import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './index.css';
import Svg_cashback from '../../../assets/svgs/wallet/cashback.svg';
import Svg_earn from '../../../assets/svgs/wallet/earn.svg';
import Svg_deposit from '../../../assets/svgs/wallet/deposit.svg';
import Theme from '../../../assets/theme';

const styles = {
  container: { borderRadius: 12, padding: 16, backgroundColor: Theme.colors.gray9, width: '100%', marginBottom: 12, },
  priceTxt: { fontSize: 15, fontFamily: Theme.fonts.semiBold, color: Theme.colors.red1 },
  descTxt: { marginTop: 3, fontSize: 16, fontFamily: Theme.fonts.medium, color: Theme.colors.text },
  title: { fontSize: 18, fontFamily: Theme.fonts.semiBold, color: Theme.colors.text },
}

/* eslint-disable react/prop-types */
const WalletTransactionItem = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onClick = (e) => {
    e.preventDefault();
    if (data.type == 'cashback' && data.source_id != null) {
      navigate(`/order/${data.source_id}`);
    }
  };

  if (data.type == 'cashback') {
    return <div onClick={onClick} className={'wallet-transaction-item'}>
      <img src={Svg_cashback} />
      <div style={{ flex: 1, marginLeft: 12 }}>
        <div style={styles.title}>{t('wallet_transaction.cashback')}</div>
        {
          data.category == 'cashback_level_earning' &&
          <div style={styles.descTxt}>{t('wallet_transaction.order_from')} {data.order_data != null ? data.order_data.title : ''}</div>
        }
        {
          data.category == 'cashback_use_order' &&
          <div style={styles.descTxt}>{t('wallet_transaction.used_on_order')}</div>
        }
        {
          data.category == 'cashback_back_from_order' &&
          <div style={styles.descTxt}>{t('wallet_transaction.cashback_return')}</div>
        }
        {
          data.category == 'cashback_expired_revoke' &&
          <div style={styles.descTxt}>{t('wallet_transaction.cashback_expired')}</div>
        }
      </div>
      <div style={styles.priceTxt}>{parseInt(data.amount) > 0 ? '+' : ''} {parseInt(data.amount)} L</div>
    </div>;
  }
  if (data.type == 'earn') {
    return <div onClick={onClick} className={'wallet-transaction-item'}>
      <img src={Svg_earn} />
      <div style={{ flex: 1, marginLeft: 12 }}>
        <div style={styles.title}>
          {(data.category == 'reservation_rewards') ?
            t('wallet_transaction.reserve_reward')
            :
            t('wallet_transaction.earned_reward')
          }
        </div>
        <div style={styles.descTxt}>
          {(data.category == 'reservation_rewards') ?
            t('wallet_transaction.earn_reserve_order')
            :
            t('wallet_transaction.earning')
          }
        </div>
      </div>
      <div style={styles.priceTxt}>{parseInt(data.amount) > 0 ? '+' : ''} {parseInt(data.amount)} L</div>
    </div>;
  }
  if (data.type == 'referral') {
    return <div onClick={onClick} className={'wallet-transaction-item'}>
      <img src={Svg_earn} />
      <div style={{ flex: 1, marginLeft: 12 }}>
        <div style={styles.title}>{t('wallet_transaction.referral_reward')}</div>
        <div style={styles.descTxt}>{t('wallet_transaction.invitation')}</div>
      </div>
      <div style={styles.priceTxt}>{parseInt(data.amount) > 0 ? '+' : ''} {parseInt(data.amount)} L</div>
    </div>;
  }
  if (data.type == 'deposit') {
    return <div onClick={onClick} className={'wallet-transaction-item'}>
    <img src={Svg_deposit} /> 
      <div style={{ flex: 1, marginLeft: 12 }}>
        <div style={styles.title}>
          {
            (data.category == 'transfer_deposit' || data.category == 'received_deposit') ?
              t('wallet_transaction.new_transfer') : t('wallet_transaction.deposit')
          }
        </div>
        {
          data.category == 'deposit_from_snapfood' &&
          <div style={styles.descTxt}>{t('wallet_transaction.deposit_snapfood')}</div>
        }
        {
          data.category == 'deposit_revoke_snapfood' &&
          <div style={styles.descTxt}>{t('wallet_transaction.deposit_revoke_snapfood')}</div>
        }
        {
          data.category == 'stripe_card_deposit' &&
          <div style={styles.descTxt}>{t('wallet_transaction.deposit_stripe_card')}</div>
        }
        {
          data.category == 'transfer_deposit' &&
          <div style={styles.descTxt}>{t('wallet_transaction.transfered_to')} {data.user_data?.full_name}</div>
        }
        {
          data.category == 'received_deposit' &&
          <div style={styles.descTxt}>{t('wallet_transaction.received_from')} {data.user_data?.full_name}</div>
        }
        {
          data.category == 'blog_quiz_earning' &&
          <div style={styles.descTxt}>{t('wallet.blog_quiz_earning')}</div>
        }
      </div>
      <div style={styles.priceTxt}>{parseInt(data.amount) > 0 ? '+' : ''} {parseInt(data.amount)} L</div>
    </div>;
  }
  return null;
};
/* eslint-enable react/prop-types */

export default WalletTransactionItem;
